@import '../../../../colors.scss';

.btn-secondary {
    background-color: $hallapp-secondary-color !important;
    border-color: $hallapp-secondary-color !important;
}

.rbc-toolbar-label {
    margin-top: 10px;
}

.rbc-btn-group button {
    padding: 8px;
    color: #6c757d;
}