@import '../colors.scss';


.custom-navbar {
  background: linear-gradient(90deg, #7d4ee0, #5627bc);
  color: white;
  text-align: center;
}

.custom-navbar .navbar-brand {
  margin: 0 auto;
  color: #fff;
  font-weight: 700;
}

@media (max-width: 768px) {
  .custom-navbar {
    height: 60px;
  }
}

.icon-description {
  margin-top: 5px;
  font-size: 12px;
  color: $grey-color;
}

.icon-menu-bottom {
  color: #fff;
}


.custom-navbar-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.icon-menu-bottom {
  cursor: pointer;
}

.container-pages {
  padding-top: 80px;
  background-color: $page-back-color;
  height: 100%;
  width: 100% !important;
  // padding-left: 20px;
  // padding-right: 20px;
}

.custom-sidebar {
  z-index: 10000 !important;
  width: 200px;
  background-color: #f8f9fa;
}

.nav-link {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 10px 0;
}

.nav-link i {
  margin-right: 10px;
}

.toggle-btn {
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  background-color: transparent;
  border: none;
  color: #000;
  font-weight: bold;
}

.account-section {
  margin-top: 20px;
}

.profile-section {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 8px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.profile-info {
  margin-left: 10px;
}

.profile-info strong {
  display: block;
  font-size: 0.9rem;
}

.profile-info span {
  font-size: 0.8rem;
  color: #666;
}

.sidebar-item-text {
  color: $text-color !important;
}

.dropdown-company {
  background: linear-gradient(90deg, #7d4ee0, #5627bc);
}

.submenu {
  padding-left: 20px; // Indentação para submenus
  background-color: #f8f9fa;
}