@import '../../../../colors.scss';

.rbc-today {
  background-color: #f8f0ff;
}

.rbc-current-time-indicator {
  background-color: #43854e;
}

.rbc-event {
  background-color: $hallapp-secondary-color;
}

.rbc-show-more {
  color: #7531ad !important;
}

.scheduler-container {
  margin: 2px;
  height: 99%;
  /* Ajusta o tamanho para ocupar a tela inteira menos o padding */
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.scheduler-calendar {
  flex-grow: 1;
  /* Faz o calendário ocupar o restante do espaço disponível */
  overflow: hidden;
  /* Evita scroll indesejado */
}

.hallapp-scheduler-week {
  .rbc-time-header {
    display: none;
  }

  .rbc-day-slot .rbc-timeslot-group {
    width: 300px !important;
  }

  .rbc-row .rbc-time-header-cell {
    width: 300px !important;
  }


  .rbc-time-content>.rbc-time-gutter {
    position: sticky;
    /* Define como posição fixa */
    left: 0;
    /* Garante que ela fique no início horizontalmente */
    z-index: 10;
    /* Garante que o elemento fique acima de outros elementos */
    background-color: white;
    box-shadow: 1px 0 8px rgba(0, 0, 0, 0.2);
    /* Sombra à esquerda */
    margin-top: 90px;
  }

  .custom-day-header {
    background-color: #d9bdff;
    /* Cor de fundo para diferenciar */
    border-bottom: 1px solid #ddd;
    /* Linha separadora */
    padding: 10px;
    text-align: center;
    font-weight: bold;
    z-index: 9;
    /* Garante que o cabeçalho fique acima do conteúdo */
    position: sticky;
    /* Torna o cabeçalho fixo durante o scroll */
    top: 0;
    /* Fixa no topo da coluna */
  }

  .day-class {
    font-size: 20px;
  }

  .week-name-class {
    font-size: 13px;
  }

  .month-class {
    font-size: 13px;
  }
}